import { Injectable } from '@angular/core';

import { UserGroup } from 'src/models/user-groups';

import { GenericObjectsService } from './generic-objects.service';

@Injectable({
  providedIn: 'root',
})
export class UserGroupsService extends GenericObjectsService<UserGroup> {
  protected baseUrl = '/user-groups';
}
